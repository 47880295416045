<template>
  <v-navigation-drawer
    v-model="drawer"
    width="600"
    fixed
    temporary
    stateless
    disable-route-watcher
    @input="onHandleInput"
    right
  >
    <template v-slot:prepend>
      <app-page-header :title="title" :icon="icon" @close="$emit('close')" />
    </template>
    <section class="page-content">
      <v-container fluid grid-list-lg>
        <slot name="content"></slot>
      </v-container>
    </section>
    <template v-slot:append>
      <slot name="footer"></slot>
    </template>
    <slot></slot>
  </v-navigation-drawer>
</template>

<script>
  /**
   * @project maksmart
   * @developer Halil Kılıçarslan
   * @description AppPageLayout Component Logic
   * @date 3.08.2020
   */
  import AppPageHeader from "./app-page-header";
  export default {
    name: "AppPageLayout",
    props: {
      title: String,
      icon: String
    },
    components: {
      AppPageHeader
    },
    data: () => ({
      drawer: true
    }),
    methods: {
      onHandleInput(e) {
        if (!e) {
          this.$emit("close");
        }
      }
    }
  };
</script>

<style scoped>
  .page-content {
    padding: 0 10px;
  }
</style>
